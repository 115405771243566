import { Icon } from '@dyrloz/aumee-design';
import { IIconProps } from '@dyrloz/aumee-design/dist/components/icons/Icon';

/**
 * InProgressIcon displays arrows in circle.
 */
export class InProgressIcon extends Icon {
    constructor(props: any) {
        super('InProgressIcon', props);
    }

    render(): JSX.Element {
        let {
            color = 'primary',
            size = '16',
            side = null,
            colorVariant = 'light',
            rotation = 0,
        }: IIconProps = this.props;
        return (
            <svg
                id={this.id}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                style={this.createFillStyle(color, colorVariant, rotation)}
                className={`aumee--icon ${!side ? '' : `aumee--icon--${side}`}`}
                viewBox="0 0 16 16"
            >
                <path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.5.5 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244z" />
            </svg>
        );
    }
}
