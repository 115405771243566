export const isMobile = () => {
    return window.innerWidth <= 700;
};

export const isTablet = () => {
    return window.innerWidth <= 1450;
};

export const STATUS_TO_COLOR = ['white', 'green', 'blue', 'orange', 'red'];

export const ID_PATTERN = '^[0-9a-z]{24}$';
