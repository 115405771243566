import './Supporter.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import {
    AbsoluteIconButton,
    Button,
    ButtonGroup,
    CheckBox,
    ColorVariant,
    Container,
    DateTimePicker,
    FlagIcon,
    IconFactory,
    InputText,
    Locales,
    Select,
    Spacer,
    SpanHTMLParser,
    Table,
    TextArea,
} from '@dyrloz/aumee-design';
import { TotalData } from '../../components/total-data/TotalData';
import { IconColors } from '@dyrloz/aumee-design/dist/components/icons/IconFactory';
import { CountGroupBy, Message, MiniRequest, Request } from './Supporter.type';
import { EditableText } from '../../components/editable-text/EditableText';
import { ID_PATTERN } from '../../utils/utils';

export type SupporterState = {
    filters: { [key: string]: string };

    requests: MiniRequest[];
    pagesLoaded: number[];
    requestsDisplayed: Request[];
    requestSelected: Request;
    newMessage: Message;

    countOpen: number;
    countInProgress: number;
    countPending: number;
    countClosed: number;

    isRequestMessagesDisplayed: boolean;
    isRefreshNeeded: boolean;
    isDataLoading: boolean;
    isRequestsLoading: boolean;
    isEditingShortDescription: boolean;
};

/**
 * The Supporter app allows the user to check the data of the requests, with total of requests by status
 * A table displays the list of requests corresponding to the filters.
 * When selecting a row, it display the request details.
 */
class Supporter extends AumeeApp<SupporterState> {
    private requestHeaders = {
        locale: AumeeTranslator.translate('supporter.request.locale'),
        _id: AumeeTranslator.translate('supporter.request.id'),
        created_at: AumeeTranslator.translate('supporter.request.created_at'),
        account_id: AumeeTranslator.translate('supporter.request.account_id'),
        category: AumeeTranslator.translate('supporter.request.category'),
        status: AumeeTranslator.translate('supporter.request.status'),
        updated_at: AumeeTranslator.translate('supporter.request.updated_at'),
    };
    private columnWidth = {
        locale: {
            percent: 5,
            minWidth: 30,
        },
        _id: {
            percent: 20,
            minWidth: 120,
        },
        created_at: {
            percent: 15,
            minWidth: 100,
        },
        account_id: {
            percent: 20,
            minWidth: 120,
        },
        category: {
            percent: 5,
            minWidth: 30,
        },
        status: {
            percent: 5,
            minWidth: 30,
        },
        updated_at: {
            percent: 15,
            minWidth: 100,
        },
    };

    private categories = ['bug', 'feature', 'question', 'other'];

    private categoryIcons: {
        [key: string]: {
            icon: string;
            color: IconColors;
            colorVariant: ColorVariant;
        };
    } = {
        feature: { icon: 'add', color: 'primary', colorVariant: '' },
        question: { icon: 'help', color: 'warning', colorVariant: '' },
        bug: { icon: 'bug', color: 'alert', colorVariant: '' },
        other: { icon: 'globe', color: 'grey', colorVariant: '' },
    };

    private status = ['open', 'in_progress', 'pending', 'closed'];

    private statusIcons: {
        [key: string]: {
            icon: string;
            color: IconColors;
            colorVariant: ColorVariant;
        };
    } = {
        open: { icon: 'open', color: 'alert', colorVariant: '' },
        in_progress: {
            icon: 'in_progress',
            color: 'warning',
            colorVariant: '',
        },
        pending: { icon: 'pending', color: 'grey', colorVariant: '' },
        closed: { icon: 'validate', color: 'grey', colorVariant: '' },
    };

    private requestDisplayComponents = {
        locale: (value: Locales) => <FlagIcon locale={value} width="24" />,
        _id: (value: string) => (
            <a className="id-display">
                {value.slice(0, 12)}
                <br />
                {value.slice(12)}
            </a>
        ),
        created_at: (value: string) => (
            <SpanHTMLParser
                className="date"
                label={value.replace('T', '<br>').replace('Z', '')}
            />
        ),
        account_id: (value: string) => (
            <span className="id-display">
                {value.slice(0, 12)}
                <br />
                {value.slice(12)}
            </span>
        ),
        category: (value: string) =>
            IconFactory.createIcon({
                icon: this.categoryIcons[value].icon,
                size: '24',
                color: this.categoryIcons[value].color,
                colorVariant: this.categoryIcons[value].colorVariant,
            }),
        status: (value: string) =>
            IconFactory.createIcon({
                icon: this.statusIcons[value].icon,
                size: '24',
                color: this.statusIcons[value].color,
                colorVariant: this.statusIcons[value].colorVariant,
            }),
        updated_at: (value: string) => (
            <SpanHTMLParser
                className="date"
                label={value.replace('T', '<br>').replace('Z', '')}
            />
        ),
    };

    constructor(props: any) {
        super('Supporter', props);

        // GET VALUE FROM URL OR DEFAULT ONE, THEN SET IN URL
        const queryParameters = AumeeURL.extractURLParameters();

        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            request_id: (queryParameters.request_id as string) || 'all',
            account_id: (queryParameters.account_id as string) || 'all',
            start_date:
                (queryParameters.start_date as string) ||
                new Date(
                    actualDate.getFullYear(),
                    actualDate.getMonth() - 1,
                    actualDate.getDate(),
                ).toISOString(),
            end_date:
                (queryParameters.end_date as string) ||
                actualDate.toISOString(),
            category: decodeURIComponent(
                (queryParameters.category as string) || 'all',
            ),
            status: decodeURIComponent(
                (queryParameters.status as string) || 'all',
            ),
            range: (queryParameters.range as string) || '10',
            page: '1',
        };

        this.state = {
            filters,
            requests: [],
            pagesLoaded: [],
            requestsDisplayed: [],
            requestSelected: null,
            newMessage: {
                value: '',
                is_customer: false,
                is_internal: false,
                is_closing: false,
                date: null,
            },
            countOpen: 0,
            countInProgress: 0,
            countPending: 0,
            countClosed: 0,
            isRequestMessagesDisplayed: false,
            isRefreshNeeded: false,
            isDataLoading: false,
            isRequestsLoading: false,
            isEditingShortDescription: false,
        };

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            }
        }

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
        this.onRequestSelected = this.onRequestSelected.bind(this);
        this.onRefreshClicked = this.onRefreshClicked.bind(this);
        this.onReset = this.onReset.bind(this);

        this.onRequestPropertyChange = this.onRequestPropertyChange.bind(this);
        this.onSendMessage = this.onSendMessage.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData(): Promise<void> {
        this.setState({
            isDataLoading: true,
        });

        const { countOpen, countInProgress, countPending, countClosed } =
            await this.getRequestsCount();

        const requests = await this.getRequests();

        if (this.state.filters['request_id'] !== 'all') {
            const request = await this.getRequest(
                this.state.filters['request_id'],
            );
            if (request) this.state.requestsDisplayed.push(request);
        }

        this.setState({
            pagesLoaded: [1],
            requests,
            countOpen,
            countInProgress,
            countPending,
            countClosed,
            isRefreshNeeded: false,
        });

        this.props.dispatch(setAppLoading({ appLoading: '' }));
        this.setState({
            isDataLoading: false,
        });
    }

    /**
     * Get the count of requests by status
     * @returns The count of requests by status
     */
    private async getRequestsCount() {
        const requestParameters = AumeeURL.getURLParametersQuery();

        const count: CountGroupBy = await AumeeClient.get(
            '/requests/count' +
                requestParameters
                    .replace(/&(page|range)=[0-9]+/g, '')
                    .replace(/&request_id=[0-9a-f]{24}/g, '') +
                '&group_by=status',
        )
            .then((res) => res.json())
            .then((res) => res.data);

        const countOpen = count.data.find((d) => d._id === 'open')?.sum || 0;
        const countInProgress =
            count.data.find((d) => d._id === 'pending')?.sum || 0;
        const countPending =
            count.data.find((d) => d._id === 'pending')?.sum || 0;
        const countClosed =
            count.data.find((d) => d._id === 'closed')?.sum || 0;

        return {
            countOpen,
            countInProgress,
            countPending,
            countClosed,
        };
    }

    /**
     * Get the requests from the query parameters.
     * @returns Array of requests.
     */
    private async getRequests(): Promise<MiniRequest[]> {
        const requestParameters = AumeeURL.getURLParametersQuery();

        const requests: MiniRequest[] = await AumeeClient.get(
            '/requests' +
                requestParameters.replace(/&request_id=[0-9a-f]{24}/g, ''),
        )
            .then((res) => res.json())
            .then((res) => res.data);

        return requests;
    }

    /**
     * Get request by id, and also check messages if true.
     * Display the loading only if messages are checked.
     * @param requestId
     * @returns
     */
    private async getRequest(
        requestId: string,
        checkMessages = true,
    ): Promise<Request> {
        this.setState({
            isDataLoading: checkMessages,
        });

        const request: Request = await AumeeClient.get(
            '/requests/' + requestId + '?check_messages=' + checkMessages,
            false,
            {
                key: 'supporter.get_request.',
                withStatus: true,
                throwError: true,
            },
        )
            .then((res) => res.json())
            .then((res) => res.data)
            .catch((): any => null);

        if (!request) {
            this.setState({
                isDataLoading: false,
            });
            return null;
        }

        this.setState({
            isDataLoading: false,
            requestSelected: request,
        });
        this.onFilterChange('request_id', requestId);

        return request;
    }

    /**
     * Update the request with the object given on the request id mentionned.
     * @param update
     * @param requestId
     */
    private async updateRequest(
        update: {
            category?: string;
            short_description?: string;
            message?: Message;
        },
        requestId = this.state.requestSelected._id,
    ) {
        await AumeeClient.patch('/requests/' + requestId, update).then(
            async () => {
                this.refreshRequest(requestId);
            },
        );
    }

    /**
     * Refresh the given request and set it into selected request.
     * @param requestId
     */
    private async refreshRequest(requestId: string, checkMessages = false) {
        const request = await this.getRequest(requestId, checkMessages);

        if (request) {
            const indexRequest = this.state.requestsDisplayed.findIndex(
                (r) => r._id === requestId,
            );
            this.state.requestsDisplayed[indexRequest] = request;
        }
    }

    private onFilterChange(attribute: string, value: string) {
        if (value === '' || value === null) {
            value = 'all';
        }
        if (value !== 'all') {
            AumeeURL.addQueryParameterToURL(attribute + '=' + value);
        } else {
            AumeeURL.removeQueryParameterFromURL(attribute);
        }
        const filters = {
            ...this.state.filters,
            [attribute]: value,
        };
        this.setState({
            filters,
            isRefreshNeeded: true,
        });
    }

    private async onPageChange(newPage: number) {
        AumeeURL.addQueryParameterToURL('page=' + newPage);

        let requestsConcat: MiniRequest[] = this.state.requests;
        if (!this.state.pagesLoaded.includes(newPage)) {
            const requestParameters = AumeeURL.getURLParametersQuery();
            this.setState({
                isRequestsLoading: true,
            });
            let requests: MiniRequest[] = await AumeeClient.get(
                '/requests' +
                    requestParameters.replace(/&request_id=[0-9a-f]{24}/g, ''),
            )
                .then((res) => res.json())
                .then((res) => res.data);

            const actualPage = parseInt(this.state.filters['page']);

            if (newPage < actualPage) {
                requestsConcat = requests.concat(requestsConcat);
            } else if (newPage > actualPage) {
                requestsConcat = requestsConcat.concat(requests);
            } else {
                requestsConcat = requests;
            }
            this.state.pagesLoaded.push(newPage);
        }

        this.onFilterChange('page', newPage.toString());

        this.setState({
            requests: requestsConcat,
            isRequestsLoading: false,
        });
    }

    private onRangeChange(newRange: number) {
        if (newRange === parseInt(this.state.filters['range'])) return;
        this.onFilterChange('range', newRange.toString());
        this.onRefreshClicked();
    }

    private async onRequestSelected(row: number) {
        const requestId = this.state.requests[row - 1]._id;

        const requestFound = this.state.requestsDisplayed.find(
            (r) => r._id === requestId,
        );

        if (requestFound) {
            this.setState({
                requestSelected: requestFound,
            });
            this.onFilterChange('request_id', requestId);
            return;
        }

        const request = await this.getRequest(requestId);
        if (request) this.state.requestsDisplayed.push(request);
    }

    private onRefreshClicked() {
        this.onFilterChange('page', '1');
        setTimeout(() => this.refreshAppData(), 100);
    }

    private onReset() {
        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            ...this.state.filters,
            event: 'all',
            session_id: 'all',
            type: 'all',
            start_date: new Date(
                actualDate.getFullYear(),
                actualDate.getMonth(),
                actualDate.getDate(),
            ).toISOString(),
            end_date: actualDate.toISOString(),
            data: 'all',
            category: 'all',
            status: 'all',
        };

        this.setState({
            filters,
            isRefreshNeeded: true,
        });

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            } else {
                AumeeURL.removeQueryParameterFromURL(key);
            }
        }
    }

    private onRequestPropertyChange(property: string, value: string) {
        (this.state.requestSelected as any)[property] = value;

        this.setState({
            requestSelected: this.state.requestSelected,
        });

        this.updateRequest({ [property]: value });
    }

    private onSendMessage() {
        if (this.state.newMessage.value.length < 5) return;

        const messageToSend: Message = {
            ...this.state.newMessage,
            value: this.state.newMessage.value.replace(/\n/g, '<br>'),
            date: new Date().toISOString(),
        };
        this.state.requestSelected.messages.push(messageToSend);

        this.setState({
            newMessage: {
                value: '',
                is_closing: false,
                is_internal: false,
                is_customer: false,
                date: null,
            },
        });

        this.updateRequest({ message: messageToSend });
    }

    render() {
        const { isTabletScreen, isMobileScreen } = this.props;

        const {
            filters,
            requests,
            requestsDisplayed,
            requestSelected,
            newMessage,

            countOpen,
            countInProgress,
            countPending,
            countClosed,

            isRequestMessagesDisplayed,
            isRefreshNeeded,
            isDataLoading,
            isRequestsLoading,
            isEditingShortDescription,
        } = this.state;

        const countTotal =
            countOpen + countInProgress + countPending + countClosed;

        return (
            <div id={this.id} className="supporter page">
                <Container fatherId={this.id} usage="inputs" canBeHidden={true}>
                    <div className="aumee--flex-center aumee--flex-column">
                        <div className="inputs aumee--flex-center">
                            <div className="aumee--flex-center">
                                <InputText
                                    fatherId={this.id}
                                    usage="search-request"
                                    type="search"
                                    size="medium"
                                    placeholder={AumeeTranslator.translate(
                                        'supporter.search.request',
                                    )}
                                    pattern={ID_PATTERN}
                                    errorMessage={AumeeTranslator.translate(
                                        'common.error.id.length',
                                    )}
                                    validationMinLength={1}
                                    value={
                                        filters['request_id'] === 'all'
                                            ? ''
                                            : filters['request_id']
                                    }
                                    onValueChange={(value) =>
                                        this.onFilterChange('request_id', value)
                                    }
                                />
                                <InputText
                                    fatherId={this.id}
                                    usage="search-account"
                                    type="search"
                                    size="medium"
                                    placeholder={AumeeTranslator.translate(
                                        'supporter.search.account',
                                    )}
                                    pattern={ID_PATTERN}
                                    errorMessage={AumeeTranslator.translate(
                                        'common.error.id.length',
                                    )}
                                    validationMinLength={1}
                                    value={
                                        filters['account_id'] === 'all'
                                            ? ''
                                            : filters['account_id']
                                    }
                                    onValueChange={(value) =>
                                        this.onFilterChange('account_id', value)
                                    }
                                />
                            </div>
                            <div className="aumee--flex-center">
                                <DateTimePicker
                                    fatherId={this.id}
                                    usage="start-date"
                                    type="datetime-local"
                                    step="milliseconds"
                                    value={filters['start_date'] as string}
                                    placeholder={AumeeTranslator.translate(
                                        'logs.start_date',
                                    )}
                                    onValueChange={(date: string) => {
                                        this.onFilterChange('start_date', date);
                                    }}
                                />
                                <DateTimePicker
                                    fatherId={this.id}
                                    usage="end-date"
                                    type="datetime-local"
                                    step="milliseconds"
                                    value={filters['end_date'] as string}
                                    min={filters['start_date'] as string}
                                    placeholder={AumeeTranslator.translate(
                                        'logs.end_date',
                                    )}
                                    onValueChange={(date: string) => {
                                        this.onFilterChange('end_date', date);
                                    }}
                                />
                            </div>
                            <div className="aumee--flex-center">
                                <Button
                                    fatherId={this.id}
                                    usage="refresh"
                                    icon="refresh"
                                    iconSize="24"
                                    onClick={this.onRefreshClicked}
                                    disabled={
                                        !isRefreshNeeded ||
                                        (filters['request_id'] !== 'all' &&
                                            filters['request_id'].length !==
                                                24) ||
                                        (filters['account_id'] !== 'all' &&
                                            filters['account_id'].length !== 24)
                                    }
                                />
                                <Spacer size="tiny" direction="vertical" />
                                <Button
                                    fatherId={this.id}
                                    usage="reset"
                                    icon="cross"
                                    iconSize="24"
                                    style="warning"
                                    onClick={this.onReset}
                                />
                            </div>
                        </div>
                        <div className="groups aumee--flex-center">
                            <ButtonGroup
                                fatherId={this.id}
                                usage="category"
                                values={this.categories}
                                valuesIcon={['bug', 'add', 'help', 'globe']}
                                valueType="icon"
                                labelSize="small"
                                isMultipleValue={true}
                                tooltipValues={[
                                    AumeeTranslator.translate(
                                        'supporter.category.bug',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.category.feature',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.category.question',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.category.other',
                                    ),
                                ]}
                                value={
                                    filters['category'] === 'all'
                                        ? ''
                                        : filters['category']
                                }
                                onClick={(value) =>
                                    this.onFilterChange('category', value)
                                }
                            />
                            <Spacer direction="vertical" size="medium" />
                            <ButtonGroup
                                fatherId={this.id}
                                usage="status"
                                values={this.status}
                                valuesIcon={[
                                    'open',
                                    'in_progress',
                                    'pending',
                                    'validate',
                                ]}
                                valueType="icon"
                                labelSize="small"
                                isMultipleValue={true}
                                tooltipValues={[
                                    AumeeTranslator.translate(
                                        'supporter.status.open',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.status.in_progress',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.status.pending',
                                    ),
                                    AumeeTranslator.translate(
                                        'supporter.status.closed',
                                    ),
                                ]}
                                value={
                                    filters['status'] === 'all'
                                        ? ''
                                        : filters['status']
                                }
                                onClick={(value) =>
                                    this.onFilterChange('status', value)
                                }
                            />
                        </div>
                    </div>
                </Container>
                <Spacer />
                <Container
                    fatherId={this.id}
                    usage="data"
                    padding="epic"
                    isLoading={isDataLoading}
                >
                    <div className="content aumee--flex-column">
                        <ButtonGroup
                            fatherId={this.id}
                            usage="requests"
                            values={['search'].concat(
                                requestsDisplayed.map((r) => r._id),
                            )}
                            valuesIcon={['search'].concat(
                                requestsDisplayed.map(
                                    (r) => this.categoryIcons[r.category].icon,
                                ),
                            )}
                            valuesClass={['search'].concat(
                                requestsDisplayed.map((r) => r.status),
                            )}
                            valueType="icon"
                            labelSize="small"
                            tooltipValues={['Search'].concat(
                                requestsDisplayed.map(
                                    (r) => r.short_description,
                                ),
                            )}
                            value={requestSelected?._id || 'search'}
                            onClick={(value) => {
                                const requestId =
                                    value !== 'search' ? value : null;
                                this.setState({
                                    requestSelected:
                                        this.state.requestsDisplayed.find(
                                            (r) => r._id === requestId,
                                        ),
                                });
                                this.onFilterChange('request_id', requestId);
                            }}
                        />
                        <Spacer direction="horizontal" size="small" />
                        {!requestSelected && (
                            <div className="row aumee--flex-center">
                                <div className="column aumee--flex-center">
                                    <div
                                        className={
                                            'totals aumee--flex-center ' +
                                            (isMobileScreen
                                                ? ''
                                                : 'aumee--flex-column')
                                        }
                                    >
                                        <TotalData
                                            fatherId={this.id}
                                            usage="messages"
                                            label={AumeeTranslator.translate(
                                                'supporter.total',
                                            )}
                                            value={countTotal}
                                        />
                                        <TotalData
                                            fatherId={this.id}
                                            usage="open"
                                            label={AumeeTranslator.translate(
                                                'supporter.total.open',
                                            )}
                                            value={countOpen}
                                        />
                                        <TotalData
                                            fatherId={this.id}
                                            usage="in_progress"
                                            label={AumeeTranslator.translate(
                                                'supporter.total.in_progress',
                                            )}
                                            value={countInProgress}
                                        />
                                        <TotalData
                                            fatherId={this.id}
                                            usage="pending"
                                            label={AumeeTranslator.translate(
                                                'supporter.total.pending',
                                            )}
                                            value={countPending}
                                        />
                                        <TotalData
                                            fatherId={this.id}
                                            usage="closed"
                                            label={AumeeTranslator.translate(
                                                'supporter.total.closed',
                                            )}
                                            value={countClosed}
                                        />
                                    </div>
                                </div>
                                <div className="table">
                                    <Table
                                        fatherId={this.id}
                                        usage="requests"
                                        headers={this.requestHeaders}
                                        widths={this.columnWidth}
                                        useDisplayComponent={
                                            this.requestDisplayComponents
                                        }
                                        values={requests}
                                        total={countTotal}
                                        isCoveringHeight={false}
                                        isValuesScrollingX={true}
                                        range={parseInt(filters['range'])}
                                        page={parseInt(filters['page'])}
                                        isLoading={isRequestsLoading}
                                        onPageChange={this.onPageChange}
                                        onRangeChange={this.onRangeChange}
                                        onRowClick={this.onRequestSelected}
                                    />
                                </div>
                            </div>
                        )}
                        {requestSelected && (
                            <AbsoluteIconButton
                                fatherId={this.id}
                                usage="refresh-request"
                                horizontalPosition="left"
                                verticalPosition="top"
                                marginHorizontal="epic"
                                marginVertical="epic"
                                icon="refresh"
                                onClick={() =>
                                    this.refreshRequest(
                                        this.state.requestSelected._id,
                                        true,
                                    )
                                }
                            />
                        )}
                        {requestSelected && (
                            <AbsoluteIconButton
                                fatherId={this.id}
                                usage="close-request"
                                horizontalPosition="right"
                                verticalPosition="top"
                                marginHorizontal="epic"
                                marginVertical="epic"
                                icon="cross"
                                onClick={() => {
                                    this.onFilterChange('request_id', null);
                                    this.setState({
                                        requestSelected: null,
                                        requestsDisplayed:
                                            this.state.requestsDisplayed.filter(
                                                (r) =>
                                                    r._id !==
                                                    requestSelected._id,
                                            ),
                                    });
                                }}
                            />
                        )}
                        {requestSelected && (
                            <div className="request aumee--flex-center">
                                <div
                                    className={`infos ${
                                        !isMobileScreen ||
                                        (isMobileScreen &&
                                            isRequestMessagesDisplayed)
                                            ? 'reduced'
                                            : ''
                                    } ${
                                        isMobileScreen ? 'mobile' : ''
                                    } aumee--flex-center aumee--flex-column`}
                                >
                                    {isMobileScreen &&
                                        isRequestMessagesDisplayed && (
                                            <Button
                                                fatherId={this.id}
                                                usage="open-details"
                                                icon="dots"
                                                iconSize="24"
                                                onClick={() =>
                                                    this.setState({
                                                        isRequestMessagesDisplayed:
                                                            false,
                                                    })
                                                }
                                            />
                                        )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div className="info flag">
                                            <FlagIcon
                                                fatherId={this.id}
                                                usage="request-locale"
                                                locale={requestSelected.locale}
                                                width="32"
                                            />
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_created-at"
                                            className="info"
                                        >
                                            <span className="title">
                                                {AumeeTranslator.translate(
                                                    'supporter.request.created_at',
                                                )}
                                            </span>
                                            <div className="value">
                                                {this.requestDisplayComponents.created_at(
                                                    requestSelected.created_at,
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div id="request_id" className="info">
                                            <span className="title">
                                                {AumeeTranslator.translate(
                                                    'supporter.request.request_id',
                                                )}
                                            </span>
                                            <div className="value">
                                                {this.requestDisplayComponents._id(
                                                    requestSelected._id,
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_account-id"
                                            className="info"
                                        >
                                            <span className="title">
                                                {AumeeTranslator.translate(
                                                    'supporter.request.account_id',
                                                )}
                                            </span>
                                            <div className="value">
                                                <a
                                                    className="id-display"
                                                    target="_blank"
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    href={`${window.location.origin}/#/accesses?app_id=${this.props.appId}&account_id=${requestSelected.account_id}`}
                                                >
                                                    {requestSelected.account_id.slice(
                                                        0,
                                                        12,
                                                    )}
                                                    <br />
                                                    {requestSelected.account_id.slice(
                                                        12,
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_session-id"
                                            className="info"
                                        >
                                            <span className="title">
                                                {AumeeTranslator.translate(
                                                    'supporter.request.session_id',
                                                )}
                                            </span>
                                            <div className="value">
                                                <a
                                                    target="blank"
                                                    href={`${
                                                        window.location.origin
                                                    }/#/logs?app_id=${
                                                        this.props.appId
                                                    }&start_date=${new Date(
                                                        new Date(
                                                            requestSelected.created_at,
                                                        ).toDateString(),
                                                    ).toISOString()}&end_date=${
                                                        requestSelected.created_at
                                                    }&session_id=${
                                                        requestSelected.session_id
                                                    }`}
                                                >
                                                    {requestSelected.session_id}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_category"
                                            className="info"
                                        >
                                            <div className="value">
                                                {this.requestDisplayComponents.category(
                                                    requestSelected.category,
                                                )}
                                                <Select
                                                    fatherId={this.id}
                                                    usage="request-category"
                                                    size="short"
                                                    placeholder={AumeeTranslator.translate(
                                                        'supporter.category',
                                                    )}
                                                    values={this.categories.map(
                                                        (c) => {
                                                            return {
                                                                value: c,
                                                                text: AumeeTranslator.translate(
                                                                    'supporter.category.' +
                                                                        c,
                                                                ),
                                                            };
                                                        },
                                                    )}
                                                    useValueAsOptionId={true}
                                                    defaultValue={
                                                        requestSelected.category
                                                    }
                                                    onValueChange={(value) =>
                                                        this.onRequestPropertyChange(
                                                            'category',
                                                            value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_status"
                                            className="info"
                                        >
                                            <div className="value">
                                                {this.requestDisplayComponents.status(
                                                    requestSelected.status,
                                                )}
                                                <span>
                                                    {AumeeTranslator.translate(
                                                        'supporter.status.' +
                                                            requestSelected.status,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            !isRequestMessagesDisplayed)) && (
                                        <div
                                            id="request_updated-at"
                                            className="info"
                                        >
                                            <span className="title">
                                                {AumeeTranslator.translate(
                                                    'supporter.request.updated_at',
                                                )}
                                            </span>
                                            <div className="value">
                                                {this.requestDisplayComponents.updated_at(
                                                    requestSelected.updated_at,
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`messages ${
                                        isMobileScreen &&
                                        !isRequestMessagesDisplayed
                                            ? 'reduced'
                                            : ''
                                    } aumee--flex-center aumee--flex-column`}
                                >
                                    {isMobileScreen &&
                                        !isRequestMessagesDisplayed && (
                                            <Button
                                                fatherId={this.id}
                                                usage="open-messages"
                                                icon="chat"
                                                iconSize="24"
                                                onClick={() =>
                                                    this.setState({
                                                        isRequestMessagesDisplayed:
                                                            true,
                                                    })
                                                }
                                            />
                                        )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            isRequestMessagesDisplayed)) && (
                                        <div className="short-description">
                                            <EditableText
                                                fatherId={this.id}
                                                usage="short_description"
                                                isInputVisible={
                                                    isEditingShortDescription
                                                }
                                                isLoadingVisible={false}
                                                isHTML={false}
                                                keyDisplayed={
                                                    'short_description'
                                                }
                                                value={
                                                    requestSelected.short_description
                                                }
                                                placeholder={AumeeTranslator.translate(
                                                    'supporter.request.short_description',
                                                )}
                                                isAllowedToEdit={true}
                                                onValidate={(key, value) => {
                                                    this.onRequestPropertyChange(
                                                        key,
                                                        value,
                                                    );
                                                    this.setState({
                                                        isEditingShortDescription:
                                                            false,
                                                    });
                                                }}
                                                onEscapePress={() =>
                                                    this.setState({
                                                        isEditingShortDescription:
                                                            false,
                                                    })
                                                }
                                                onTextClick={() =>
                                                    this.setState({
                                                        isEditingShortDescription:
                                                            true,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            isRequestMessagesDisplayed)) && (
                                        <div className="chat">
                                            {requestSelected.messages
                                                .sort(
                                                    (a, b) =>
                                                        new Date(
                                                            b.date,
                                                        ).getTime() -
                                                        new Date(
                                                            a.date,
                                                        ).getTime(),
                                                )
                                                .map((m) => (
                                                    <div
                                                        className={
                                                            'message ' +
                                                            (m.is_customer
                                                                ? 'left'
                                                                : 'right') +
                                                            (m.is_internal
                                                                ? ' note'
                                                                : '')
                                                        }
                                                    >
                                                        <SpanHTMLParser
                                                            className="value"
                                                            label={m.value}
                                                        />
                                                        <span className="date aumee--flex-center">
                                                            {m.is_closing &&
                                                                IconFactory.createIcon(
                                                                    {
                                                                        icon: 'validate',
                                                                        size: '16',
                                                                        color: m.is_internal
                                                                            ? 'tertiary'
                                                                            : 'secondary',
                                                                        colorVariant:
                                                                            m.is_internal
                                                                                ? ''
                                                                                : 'light',
                                                                    },
                                                                )}
                                                            {m.is_closing && (
                                                                <Spacer
                                                                    size="tiny"
                                                                    direction="vertical"
                                                                />
                                                            )}
                                                            {new Date(
                                                                m.date,
                                                            ).toLocaleString()}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                    {(!isMobileScreen ||
                                        (isMobileScreen &&
                                            isRequestMessagesDisplayed)) && (
                                        <div className="add-message aumee--flex-center">
                                            <TextArea
                                                fatherId={this.id}
                                                usage="new-message"
                                                size="fill"
                                                fillHeight={true}
                                                placeholder={AumeeTranslator.translate(
                                                    'supporter.request.new_message',
                                                )}
                                                pattern={'.{5,}'}
                                                errorMessage={AumeeTranslator.translate(
                                                    'supporter.request.new_message.min_length',
                                                )}
                                                validationMinLength={1}
                                                value={newMessage.value}
                                                type="html"
                                                onValueChange={(value) => {
                                                    newMessage.value = value;
                                                    this.setState({
                                                        newMessage,
                                                    });
                                                }}
                                                onEnterPress={
                                                    this.onSendMessage
                                                }
                                            />
                                            <div className="actions aumee--flex-center aumee--flex-column">
                                                <CheckBox
                                                    fatherId={this.id}
                                                    usage="note"
                                                    label={AumeeTranslator.translate(
                                                        'supporter.request.is_internal',
                                                    )}
                                                    checked={
                                                        newMessage.is_internal
                                                    }
                                                    onValueChange={(
                                                        value: boolean,
                                                    ) => {
                                                        newMessage.is_internal =
                                                            value;
                                                        this.setState({
                                                            newMessage,
                                                        });
                                                    }}
                                                />
                                                <Spacer size="tiny" />
                                                <CheckBox
                                                    fatherId={this.id}
                                                    usage="close"
                                                    label={AumeeTranslator.translate(
                                                        'supporter.request.is_closing',
                                                    )}
                                                    checked={
                                                        newMessage.is_closing
                                                    }
                                                    onValueChange={(
                                                        value: boolean,
                                                    ) => {
                                                        newMessage.is_closing =
                                                            value;
                                                        this.setState({
                                                            newMessage,
                                                        });
                                                    }}
                                                />
                                                <Spacer size="tiny" />
                                                <Button
                                                    fatherId={this.id}
                                                    usage="send-message"
                                                    label={AumeeTranslator.translate(
                                                        'supporter.request.send_message',
                                                    )}
                                                    icon="pending"
                                                    disabled={
                                                        this.state.newMessage
                                                            .value.length < 5
                                                    }
                                                    onClick={this.onSendMessage}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(Supporter);
