import { useNavigate } from 'react-router-dom';
import { AumeeErrorBoundary, Helper } from '@dyrloz/aumee-design';
import { Toast } from '@dyrloz/aumee-design';
import {
    AumeeClient,
    AumeeLogger,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import { useState } from 'react';
import Manager from './apps/Manager';

export const App = () => {
    AumeeURL.setNavigation(useNavigate());
    const [hasMessage, sethasMessage] = useState(false);
    const [message, setMessage] = useState('');

    AumeeClient.setOnServerError((error: Error) => {
        sethasMessage(true);
        setMessage(error.message);
    });

    return (
        <AumeeErrorBoundary
            onErrorCaught={(error: Error) => {
                AumeeLogger.logError(error);
                sethasMessage(true);
                setMessage('global');
            }}
        >
            <div>
                <Manager />
                <Helper
                    fatherId="app"
                    categories={[
                        {
                            value: 'bug',
                            text: AumeeTranslator.translate('helper.bug'),
                        },
                        {
                            value: 'question',
                            text: AumeeTranslator.translate('helper.question'),
                        },
                        {
                            value: 'other',
                            text: AumeeTranslator.translate('helper.other'),
                        },
                    ]}
                    helpMessage={AumeeTranslator.translate('helper.title')}
                    categoriesPlaceholder={AumeeTranslator.translate(
                        'helper.category',
                    )}
                    messagePlaceholder={AumeeTranslator.translate(
                        'helper.message',
                    )}
                    errorMessageMinLength={AumeeTranslator.translate(
                        'helper.message.min',
                    )}
                    errorMessageMaxLength={AumeeTranslator.translate(
                        'helper.message.max',
                    )}
                    closeLabel={AumeeTranslator.translate('common.close')}
                    sendLabel={AumeeTranslator.translate('common.send')}
                    isConnected={true}
                    onSend={(data) => {
                        AumeeClient.post('/requests', data);
                    }}
                />
                <Toast
                    fatherId="app"
                    hasMessage={hasMessage}
                    message={AumeeTranslator.translate('error.' + message)}
                    color={
                        message.indexOf('warning') > -1 ? 'warning' : 'alert'
                    }
                    onExit={() => sethasMessage(false)}
                />
            </div>
        </AumeeErrorBoundary>
    );
};
