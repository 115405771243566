import { Icon } from '@dyrloz/aumee-design';
import { IIconProps } from '@dyrloz/aumee-design/dist/components/icons/Icon';

/**
 * PendingIcon displays a triple dots.
 */
export class PendingIcon extends Icon {
    constructor(props: any) {
        super('PendingIcon', props);
    }

    render(): JSX.Element {
        let {
            color = 'primary',
            size = '16',
            side = null,
            colorVariant = 'light',
            rotation = 0,
        }: IIconProps = this.props;
        return (
            <svg
                id={this.id}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                style={this.createFillStyle(color, colorVariant, rotation)}
                className={`aumee--icon ${!side ? '' : `aumee--icon--${side}`}`}
                viewBox="0 0 16 16"
            >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
        );
    }
}
